import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = ["field", "btn"];

  connect() {
    console.log("comments#connect");
  }

  focus(ev) {
    console.log("focus");
    this.btnTarget.classList.remove("d-none");
  }
}
