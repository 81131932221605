import { Controller } from "@hotwired/stimulus";
import { jsonHeaders, headers, fadeOut } from "../utils";
import VanillaContextMenu from "vanilla-context-menu";
import ActionSheet from "../modules/action_sheet";

// Connects to data-controller="tile"
export default class extends Controller {
  static targets = ["tile"];
  static values = {
    url: String,
    itemUrl: String,
    containersUrl: String,
    shareUrl: String,
    copyUrl: String,
    exportUrl: String,
    type: String,
    boardId: String,
    associatedId: Number,
  };

  connect() {
    this.buildContextMenu();

    this.setupListeners();
  }

  setupListeners() {
    // if (!this.listenValue) return;

    this.element.querySelectorAll("[data-vt-trigger").forEach((el) => {
      el.style.cursor = "pointer";
      el.onclick = (ev) => {
        const triggers = el.dataset.vtTrigger.split(",");
        if (triggers.includes(document.body.dataset.view)) {
          this.show(ev);
        }
      };
    });
  }

  show(ev) {
    ev.preventDefault();

    // check for presentation view
    const url =
      document.body.dataset.view == "presentation"
        ? this.itemUrlValue
        : this.urlValue;

    fetch(url, {
      method: "GET",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);

        document.body.classList.add("info-panel-active");
        document.body
          .querySelector("#canvas .overlay")
          ?.addEventListener("click", this.close);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  async update(idea) {
    const body = {
      idea: idea,
    };

    fetch(this.urlValue + ".json", {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => {
        if (!resp.ok) {
          resp.json().then((json) => {
            window.notyf.error(json.errors);
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        window.notyf.error(err);
      });
  }

  async delete() {
    fetch(this.urlValue, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((err) => {
        window.notyf.errors(err);
      });
  }

  move(ev) {}

  buildContextMenu() {
    this.contextMenu = new VanillaContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: this.menuItems(),
      preventCloseOnClick: true,
    });
  }

  menuItems() {
    const menuItems = [];

    if (this.typeValue == "item") {
      menuItems.push({
        label: "Copy",
        iconClass: "fa-regular fa-copy",
        callback: () => {
          this.contextMenu?.close();

          fetch(this.copyUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      });
    }

    menuItems.push({
      label: "Move",
      iconClass: "fa-regular fa-file-export",
      callback: () => {
        this.buildContainersMenu();
      },
      nestedMenu: [],
    });

    if (this.typeMenu()) {
      menuItems.push("hr");
      menuItems.push(this.typeMenu());

      if (this.typeValue == "item") {
        menuItems.push(this.tearSheet());
      }

      menuItems.push("hr");
    }

    menuItems.push(
      {
        label: "Zoom",
        iconClass: "fa-regular fa-magnifying-glass",
        callback: () => {},
        nestedMenu: [
          {
            label: "1",
            callback: () => {
              const level = 1;
              // this.element.dataset.zoom = level;
              this.update({ col_span: level });
              this.contextMenu?.close();
            },
          },
          {
            label: "2",
            callback: () => {
              const level = 2;
              // this.element.dataset.zoom = level;
              this.update({ col_span: level });
              this.contextMenu?.close();
            },
          },
          {
            label: "3",
            callback: () => {
              const level = 3;
              // this.element.dataset.zoom = level;
              this.update({ col_span: level });
              this.contextMenu?.close();
            },
          },
        ],
      },
      "hr",
      {
        label: this.deletableValue ? "Delete" : "Remove",
        callback: () => {
          if (this.typeValue == "list") {
            const as = new ActionSheet({
              title: "Delete List",
              text: `Are you sure you want to delete this list? This action cannot be undone.`,
            });

            as.yesNoMenu(() => {
              this.contextMenu.close();
              this.delete();
            });
          } else {
            this.contextMenu.close();
            this.delete();
          }
        },
        iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
      }
    );

    return menuItems;
  }

  buildContainersMenu() {
    const observer = this.onNestedContextMenuCreated(async (target) => {
      observer.disconnect();

      target.innerHTML =
        "<div class='placeholder-glow flex-column'><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div><div class='placeholder w-100 mb-1 rounded'></div></div>";

      const resp = await fetch(this.containersUrlValue);
      const containers = await resp.json();

      target.innerHTML = "";

      containers.forEach((c) => {
        const div = document.createElement("div");
        div.className = "cursor-pointer";
        div.innerHTML = `<span></span><span>${c.label}</span>`;
        div.onclick = (ev) => {
          this.update({ container_id: c.id });
          this.contextMenu?.close();
          // this.element.remove();
          fadeOut(this.element);
        };
        target.append(div);
      });
    });
  }

  onNestedContextMenuCreated(callback) {
    // Define the mutation observer
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        // Check if there are added nodes
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            // Check if the added node is an element and has the desired class
            if (
              node.nodeType === Node.ELEMENT_NODE &&
              node.classList.contains("nested-context-menu")
            ) {
              // Call the callback with the newly created element
              callback(node);

              // check positioning
              const nodeRight = node.offsetLeft + node.offsetWidth;
              if (nodeRight > document.body.offsetWidth) {
                const buffer = 16;
                const diff = nodeRight - document.body.offsetWidth;
                const newLeft = node.offsetLeft - diff - buffer;
                node.style.left = `${newLeft}px`;
              }
            }
          });
        }
      }
    });

    // Start observing the entire document for childList changes in the DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Return the observer instance so it can be stopped later
    return observer;
  }

  tearSheet() {
    return {
      label: "Tear Sheet",
      iconClass: "fa-regular fa-file-pdf",
      callback: () => {
        fetch(this.exportUrlValue, {
          headers: headers(),
        })
          .then((resp) => resp.text())
          .then((html) => {
            this.contextMenu.close();
            Turbo.renderStreamMessage(html);
          });
      },
    };
  }

  typeMenu() {
    if (this.typeValue == "item") {
      return {
        label: "Share",
        iconClass: "fa-regular fa-share",
        callback: () => {
          this.contextMenu?.close();
          fetch(this.shareUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      };
    }

    if (this.typeValue == "website") {
      return {
        label: "Edit Website",
        iconClass: "fa-regular fa-pencil",
        callback: () => {
          this.contextMenu?.close();
          fetch(
            `/app/${this.boardIdValue}/websites/${this.associatedIdValue}/edit`,
            {
              headers: headers(),
            }
          )
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      };
    }

    if (this.typeValue == "note") {
      const colors = [
        {
          hexValue: "#03A9F4",
          name: "Light Blue",
        },
        {
          hexValue: "#4CAF50",
          name: "Green",
        },
        {
          hexValue: "#8BC34A",
          name: "Light Green",
        },
        {
          hexValue: "#CDDC39",
          name: "Lime",
        },
        {
          hexValue: "#FFEB3B",
          name: "Yellow",
        },
        {
          hexValue: "#FFC107",
          name: "Amber",
        },
        {
          hexValue: "#FF9800",
          name: "Orange",
        },
        {
          hexValue: "#9E9E9E",
          name: "Grey",
        },
      ];

      const colorMenu = [];
      colors.forEach((color) => {
        colorMenu.push({
          label: color.name,
          callback: () => {
            this.tileTarget.style.backgroundColor = color.hexValue;
            this.update({
              note_attributes: {
                id: this.associatedIdValue,
                background_color: color.hexValue,
              },
            });
          },
        });
      });

      return {
        label: "Color",
        iconClass: "fa-regular fa-droplet",
        callback: () => {},
        nestedMenu: colorMenu,
      };
    }
  }
}
