import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="page"
export default class extends Controller {
  static targets = ["chromeExtensionCTA", "chromeExtensionMetaTag"];

  connect() {
    window.setTimeout(() => {
      this.checkChromeExtension();
    }, 4000);
  }

  checkChromeExtension() {
    if (
      this.hasChromeExtensionCTATarget &&
      this.chromeExtensionMetaTagTarget.content == "false"
    ) {
      this.chromeExtensionCTATargets.forEach((el) => el.classList.add("show"));
    }
  }
}
