import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

export default class extends Controller {
  static values = { url: String, listen: Boolean };

  connect() {
    console.log("sidepanel#connects");

    if (document.body.classList.contains("sortable-active")) return;

    if (document.body.id !== "boards") return;

    console.log("sidepanel#connect");

    this.element.addEventListener("turbo:before-fetch-response", () => {
      this.resultHandler();
    });

    const EscapePressed = () => {
      this.close(this);
    };

    const OnEscapePressed = (event) =>
      event.key === "Escape" && EscapePressed();
    document.addEventListener("keydown", OnEscapePressed);

    this.setupListeners();
  }

  resultHandler(ev) {
    console.log("resultHandler");
    document.body.classList.add("info-panel-active");
    document.body
      .querySelector("#canvas .overlay")
      ?.addEventListener("click", this.close);
  }
  show(ev) {
    ev.preventDefault();

    fetch(this.urlValue, {
      method: "GET",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.resultHandler(ev);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  close(ev) {
    document.body.classList.remove("info-panel-active");
  }

  setupListeners() {
    if (!this.listenValue) return;

    this.element.querySelectorAll("[data-vt-trigger").forEach((el) => {
      el.style.cursor = "pointer";
      el.onclick = (ev) => {
        const triggers = el.dataset.vtTrigger.split(",");
        if (triggers.includes(document.body.dataset.view)) {
          this.show(ev);
        }
      };
    });
  }
}
