import { Controller } from "@hotwired/stimulus";
import { titleCase } from "../utils";
import { show } from "@intercom/messenger-js-sdk";
// ...

// Connects to data-controller="container"
export default class extends Controller {
  static targets = ["library", "canvas", "viewLabel"];

  connect() {
    const view = localStorage.getItem("boardView") || "default";
    if (view) {
      this.canvasTarget.dataset.view = view;
      document.body.dataset.view = view;
      this.viewLabelTarget.innerText =
        view == "default" ? "Classic View" : `${titleCase(view)} View`;
    }

    window.onkeydown = (e) => {
      if (e.metaKey && e.key == "k") {
        this.libraryTarget.click();
      }
    };
  }

  changeView(ev) {
    ev.preventDefault();

    this.canvasTarget.dataset.view = ev.params.view;
    document.body.dataset.view = ev.params.view;

    localStorage.setItem("boardView", ev.params.view);
    this.viewLabelTarget.innerText = `${titleCase(ev.params.view)} View`;

    document.body.classList.remove("info-panel-active");
  }

  confirmLeaving(ev) {
    const confirmation = confirm("Are you sure you want to leave this page?");
    if (!confirmation) {
      ev.preventDefault();
    }
  }

  help(ev) {
    ev.preventDefault();
    show();
  }
}
