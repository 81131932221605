import { Controller } from "@hotwired/stimulus";
import { hideModal, jsonHeaders } from "../utils";

export default class extends Controller {
  static targets = ["option", "btn", "label", "spinner", "close", "btnWrapper"];
  static values = { url: String };

  connect() {}

  export(ev) {
    ev.preventDefault();

    this.btnTarget.disabled = true;
    this.closeTarget.style.display = "none";

    // generate share link
    console.log({ optionTargets: this.optionTargets });
    const data = {};
    this.optionTargets.forEach((el) => {
      data[el.dataset.name] = el.checked;
    });

    fetch(this.urlValue, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ permissions: data }),
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((body) => {
            window.notyf.success({
              message: body.message,
              duration: 5000,
            });

            hideModal();
          });
        }
      })
      .catch((err) => console.log(err));
  }
}
