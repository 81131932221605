import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
import { Notyf } from "notyf";
import { jsonHeaders } from "../utils";

// Connects to data-controller="item-vendor"
export default class extends Controller {
  static targets = ["field", "btns"];
  static values = { url: String, itemUrl: String, list: Array };

  connect() {
    this.init();
  }

  init() {
    const config = {
      selector: () => {
        return this.fieldTarget;
      },
      name: "bs_autoComplete",
      placeHolder: "Select or add vendor...",
      data: {
        src: this.listValue,
        keys: ["name"],
      },
      threshold: 0,
      resultsList: {
        maxResults: undefined,
        element: (list, data) => {
          if (!data.results.length && data.query.length >= 3) {
            const message = document.createElement("div");
            message.classList.add(
              "autoComplete_result_0",
              "no_result",
              "p-4",
              "cursor-pointer"
            );
            message.innerHTML = `<span>Add vendor <strong>"${data.query}"...</strong></span>`;
            list.prepend(message);

            message.addEventListener("click", () => {
              this.autoComplete.close();
              this.addNewVendor(data.query);
            });
          }
        },
        noResults: true,
      },
      resultItem: {
        highlight: true,
      },
      events: {
        input: {
          init: (event) => {
            // this.fieldTarget.classList.remove("d-none");
            // this.fieldTarget.focus();
          },
          focus: (event) => {
            this.autoComplete.start();
          },
          keydown: () => {
            console.log("keydown");
            delete this.fieldTarget.dataset.id;
            this.btnsTarget.classList.add("d-none");
          },
          selection: (event) => {
            const selection = event.detail.selection.value;
            this.fieldTarget.value = selection.name;
            this.fieldTarget.dataset.id = selection.id;

            this.btnsTarget.classList.remove("d-none");
          },
        },
      },
    };

    this.autoComplete = new autoComplete(config);
  }

  addNewVendor(val) {
    const body = {
      vendor: {
        name: val,
      },
    };

    fetch(this.urlValue, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => {
        resp
          .json()
          .then((body) => {
            console.log("body", body);
            if (body.ok) {
              this.btnsTarget.classList.remove("d-none");
              this.fieldTarget.value = val;
              this.fieldTarget.dataset.id = body.vendor.id;

              this.btnsTarget.classList.remove("d-none");
            } else {
              const notyf = new Notyf();
              notyf.error(body.errors);
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      })
      .catch((err) => console.log(err));
  }

  submit(ev) {
    ev.preventDefault();

    if (!this.fieldTarget.dataset.id) return;

    const body = {
      item: {
        vendor_id: this.fieldTarget.dataset.id,
      },
    };

    fetch(this.itemUrlValue, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((json) => {
        this.btnsTarget.classList.add("d-none");
        this.fieldTarget.blur();
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  close(ev) {
    ev.preventDefault();

    this.fieldTarget.value = "";
    this.btnsTarget.classList.add("d-none");
    this.fieldTarget.blur();
  }
}
