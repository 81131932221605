import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="public-board"
export default class extends Controller {
  static targets = ["viewGrid", "viewDetailed", "canvas"];

  connect() {
    const view = localStorage.getItem("publicBoardView") || "public-grid";
    if (view) {
      this.canvasTarget.dataset.view = view;
      document.body.dataset.view = view;

      this.viewDetailedTarget.classList.remove("active");
      this.viewGridTarget.classList.remove("active");

      switch (view) {
        case "public-grid":
          this.viewGridTarget.classList.add("active");
          break;
        case "public-detailed":
          this.viewDetailedTarget.classList.add("active");
          break;

        default:
          break;
      }
    } else {
      this.viewGridTarget.classList.add("active");
    }
  }

  changeView(ev) {
    ev.preventDefault();

    document.body.dataset.view = ev.params.view;
    this.canvasTarget.dataset.view = ev.params.view;

    localStorage.setItem("publicBoardView", ev.params.view);

    document.body.classList.remove("info-panel-active");

    this.viewDetailedTarget.classList.remove("active");
    this.viewGridTarget.classList.remove("active");
    ev.currentTarget.classList.add("active");
  }
}
