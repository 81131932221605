import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = { options: Object };

  connect() {
    const defaults = {
      container: this.element,
      title: this.element.title,
    };
    const options = Object.assign({}, defaults, this.optionsValue);

    const tooltip = new bootstrap.Tooltip(this.element, options);

    this.element.style.cursor = "default";
  }
}
