import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { csrfToken, jsonHeaders } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["dropzone", "items", "preview"];
  static values = { url: String };

  connect() {}

  bindDropzone() {
    const notyfError = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    const notyf = new Notyf({
      duration: 0,
      position: { x: "right", y: "bottom" },
    });

    const notifications = {};

    const dropzoneConfig = {
      url: this.urlValue,
      method: "post",
      paramName: "item[media]",
      acceptedFiles:
        "image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      maxFiles: 6,
      maxFilesize: 25,
      disablePreviews: true,
      headers: {
        "X-CSRF-Token": csrfToken(),
        Accept: "text/vnd.turbo-stream.html",
      },
    };

    const clickableElement = document.querySelector(".clickable");
    if (clickableElement) {
      dropzoneConfig.clickable = clickableElement;
    } else {
      dropzoneConfig.clickable = false;
    }

    if (this.dropzone) {
      this.dropzone.destroy();
      this.dropzone = null;
    }

    this.dropzone = new Dropzone(this.dropzoneTarget, dropzoneConfig);

    this.dropzone.on("maxfilesexceeded", function (file) {
      window.notyf.error(
        `${file.name} not uploaded. You may only upload 6 files at a time`
      );
      this.removeFile(file); // Optionally remove the extra file
    });

    this.dropzone.on("init", function () {
      console.log("dropzon Init");
    });

    this.dropzone.on("error", function (file, error) {
      console.log("error", { error, file });

      function isJSON(str) {
        try {
          return JSON.parse(str) && !!str;
        } catch (e) {
          return false;
        }
      }

      if (isJSON(error)) {
        const errors = JSON.parse(error);
        notyfError.error({
          message: `Error Uploading ${file.name} - ${errors.errors}`,
          dismissible: true,
        });
      } else {
        notyfError.error({
          message: `Error Uploading ${file.name} - ${error}`,
          dismissible: true,
        });
      }
    });

    this.dropzone.on("uploadprogress", (file, progress, bytesSent) => {
      const node = document.querySelector(
        `[data-dz-uploadprogress="${file.upload.uuid}"]`
      );
      console.log("node", node);

      node.nodeName === "PROGRESS"
        ? (node.value = progress)
        : (node.style.width = `${progress}%`);
    });

    this.dropzone.on("sending", (file) => {
      notifications[file.upload.uuid] = notyf.success({
        message: `Uploading ${file.name}<br><div class="progress" role="progressbar"><div class="progress-bar progress-bar-striped progress-bar-animated bg-success" data-dz-uploadprogress="${file.upload.uuid}" style="width: 0%"></div></div>`,
        dismissible: true,
      });
    });

    this.dropzone.on("addedfile", (file) => {
      if (this.dropzone.files.length > 6) {
        window.notyf.error(
          `${file.name} not uploaded. You may only upload 6 files at a time`
        );
        this.dropzone.removeFile(file);
      }
    });

    this.dropzone.on("complete", (file) => {
      this.dropzone.removeFile(file);

      notyf.dismiss(notifications[file.upload.uuid]);
    });

    this.dropzone.on("success", (file, html) => {
      // this.itemsTarget.reload();
      // document.getElementById("items").reload();
      Turbo.renderStreamMessage(html);
    });
  }
}
