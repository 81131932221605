import { Controller } from "@hotwired/stimulus";
import VanillaContextMenu from "vanilla-context-menu";
import ActionSheet from "../modules/action_sheet";
import { headers, jsonHeaders, csrfToken } from "../utils";

// Connects to data-controller="item"
export default class extends Controller {
  static targets = ["image", "file", "project", "btn", "url", "media"];
  static values = {
    id: Number,
    projectId: String,
    libraryTarget: String,
    url: String,
    image: String,
    canEdit: Boolean,
    deletable: Boolean,
    shareUrl: String,
    exportUrl: String,
    open: Boolean,
  };

  connect() {
    if (this.element.classList.contains("modal")) {
      this.modal = new bootstrap.Modal(this.element, {
        keyboard: false,
      });

      this.modal.show();
    }

    if (this.element.classList.contains("grid-item")) this.contextMenu();

    if (this.openValue) {
      this.show();
    }
  }

  show() {
    document
      .querySelectorAll(".item")
      .forEach((el) => el.classList.remove("selected"));
    this.element.classList.add("selected");

    Turbo.visit(this.urlValue, { frame: "info_panel" });

    document.body.classList.add("info-panel-active");
  }

  contextMenu() {
    const menuItems = [
      {
        label: "Copy",
        iconClass: "fa-regular fa-copy",
        callback: () => {
          const url = `/items/${this.idValue}/copy`;

          fetch(url, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      },
    ];

    if (this.canEditValue) {
      menuItems.push({
        label: "Move",
        iconClass: "fa-regular fa-file-export",
        callback: (ev) => {
          ev.preventDefault();

          const url = `/items/${this.idValue}/move`;

          fetch(url, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      });
    }

    menuItems.push(
      "hr",
      {
        label: "Share",
        iconClass: "fa-regular fa-share",
        callback: () => {
          fetch(this.shareUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      },
      {
        label: "Tear Sheet",
        iconClass: "fa-regular fa-file-pdf",
        callback: () => {
          fetch(this.exportUrlValue, {
            headers: headers(),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      },
      "hr",
      {
        label: "Add To Board",
        iconClass: "fa-regular fa-grid",
        callback: () => {
          const url = `/items/add_to_board`;
          const body = {
            project_id: this.projectIdValue,
            item_ids: [this.idValue],
          };

          fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(body),
          })
            .then((resp) => resp.text())
            .then((html) => Turbo.renderStreamMessage(html))
            .catch((err) => {
              window.notyf.errors(err);
            });
        },
      }
    );

    if (this.canEditValue) {
      menuItems.push(
        "hr",
        {
          label: "Assign Labels",
          iconClass: "fa-regular fa-tags",
          callback: () => {
            const div = document.createElement("div");
            div.dataset.itemId = this.idValue;
            div.dataset.image = this.imageValue;

            const modal = bootstrap.Modal.getInstance("#assignLabelsModal");
            modal.show(div);
          },
        },
        "hr",
        {
          label: this.deletableValue ? "Delete" : "Remove",
          callback: () => {
            this.delete();
          },
          iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
        }
      );
    }

    new VanillaContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: menuItems,
    });
  }

  thumbnailClick(ev) {
    this.imageTargets.forEach((el) => (el.src = ev.params.src));

    if (ev.params.media) {
      this.mediaTarget.classList.add("d-none");
    } else {
      this.mediaTarget.classList.remove("d-none");
    }
  }

  deleteAttachment(ev) {
    console.log("deleteAttachment", ev.currentTarget);
    ev.preventDefault();
    ev.stopPropagation();

    const el = ev.currentTarget;

    fetch(ev.params.url, {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then(() => {
        el.closest(".grid-item").remove();
      })
      .catch((err) => console.log(err));
  }

  upload(ev) {
    ev.preventDefault();

    this.btnTarget.disabled = true;
    this.btnTarget.innerText = "Uploading...";

    const action = ev.currentTarget.action;
    const files = Array.from(this.fileTarget.files);
    let index = 0;
    files.forEach((file) => {
      window.notyf.success({
        message: `Uploading ${file.name}...`,
        dismissible: true,
      });

      const formData = new FormData(); // Create a new FormData object
      formData.append("item[media]", file);
      formData.append("project_id", this.projectTarget.value);

      fetch(action, {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken(),
          Accept: "text/vnd.turbo-stream.html",
        },
        body: formData,
      })
        .then((resp) => {
          if (resp.ok) {
            resp.text().then((html) => {
              Turbo.renderStreamMessage(html);
            });
          } else {
            resp.text().then((body) => {
              const errors = JSON.parse(body).errors;

              window.notyf.error({
                message: `Error uploading ${file.name} - ${errors}`,
                dismissible: true,
              });
            });
          }

          index++;
          if (index >= files.length) {
            console.log("close");
            this.modal.hide();
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    });
  }

  removeLabel(ev) {
    ev.currentTarget.classList.remove("fa-close");
    ev.currentTarget.classList.add("fa-spin", "fa-spinner");

    fetch(ev.params.url, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.json())
      .then(() => {
        document.getElementById("details").reload();
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  delete(ev) {
    ev?.preventDefault();

    let as;
    if (this.deletableValue) {
      as = new ActionSheet({
        title: "Delete Item",
        text: `Are you sure you want to delete this item? This action cannot be undone.`,
      });
    } else {
      as = new ActionSheet({
        title: "Remove Item",
        text: `Are you sure you want to remove this item from the library?,`,
      });
    }

    as.yesNoMenu(() => {
      this.destroy();
    });
  }

  destroy() {
    fetch(this.urlValue + ".json", {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then((resp) => {
        if (resp.ok) {
          document.body.classList.remove("info-panel-active");

          document.getElementById(this.libraryTargetValue)?.remove();

          if (document.querySelectorAll("#items .item").length == 0) {
            document.getElementById("items").reload();
          }

          window.notyf.success({
            message: `Item successfully ${
              this.deletableValue ? "deleted" : "removed"
            }`,
            dismissible: true,
          });
        } else {
          resp.json().then((body) => {
            console.log("body", body);
            window.notyf.error({
              message: body.errors,
              dismissible: true,
            });
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  restore(ev) {
    ev.preventDefault();

    const body = {
      item: {
        archived: false,
      },
    };

    fetch(this.urlValue, {
      method: "PUT",
      headers: headers(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.text())
      .then((html) => {
        document.getElementById(this.libraryTargetValue)?.remove();

        window.notyf.success({
          message: `Item restored`,
          dismissible: true,
        });
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  zoom(ev) {
    const modalDiv = document.createElement("div");
    modalDiv.className = "modal fade";
    modalDiv.id = "imageModal";
    modalDiv.tabIndex = -1;
    modalDiv.setAttribute("aria-labelledby", "imageModalLabel");
    modalDiv.setAttribute("aria-hidden", "true");

    modalDiv.innerHTML = `
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">${ev.params.name}</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-center">
                      <img src="${ev.params.image}" class="img-fluid rounded" alt="Zoomed image">
                  </div>
              </div>
          </div>
      `;

    document.body.appendChild(modalDiv);

    const modal = new bootstrap.Modal(modalDiv);
    modal.show();

    modalDiv.addEventListener("hidden.bs.modal", function () {
      document.body.removeChild(modalDiv);
    });
  }
}
