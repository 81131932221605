import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import ActionSheet from "../modules/action_sheet";
import { fadeOut, csrfToken, headers } from "../utils";

// Connects to data-controller="container"
export default class extends Controller {
  static targets = ["tiles", "destroy", "tile", "deleteBtn"];
  static values = { position: Number };

  connect() {
    this.handleActions();
  }

  disconnect() {}

  tilesLoaded(ev) {
    Sortable.create(this.tilesTarget, {
      group: "shared",
      animation: 150,
      filter: ".no-drag",
      onStart: (evt) => {
        document.body.classList.toggle("sortable-active");
      },
      onMove: (evt) => {
        if (evt.dragged.classList.contains("add-block")) {
          return false;
        }

        if (document.body.dataset.view == "budget") {
          const sortableList = evt.to; // The container list

          const targetIndex = Array.prototype.indexOf.call(
            sortableList.children,
            evt.related
          ); // The index of the target item

          const itemCount = sortableList.children.length;

          // Prevent dropping in the first position or last position
          if (targetIndex === 0 || targetIndex >= itemCount - 2) {
            return false; // Cancel the move
          }
        }
      },
      onEnd: this.onEnd,
    });
  }

  onEnd(ev) {
    document.body.classList.toggle("sortable-active");

    const trigger = new CustomEvent("sortable-complete");
    window.dispatchEvent(trigger);

    if (!ev.item.dataset.ideaUrl) return false;

    function handleAddBlock(el) {
      const addBlock = el.querySelector(".add-block");
      if (el.querySelectorAll(".tile").length > 1) {
        addBlock?.classList.add("not-empty");
        addBlock?.classList.remove("empty");
      } else {
        addBlock?.classList.add("empty");
        addBlock?.classList.remove("not-empty");
      }
    }

    handleAddBlock(ev.from);
    handleAddBlock(ev.to);

    const body = {
      idea: {
        position: ev.newIndex - 1,
        container_id: ev.to.dataset.containerId,
      },
    };

    Turbo.fetch(ev.item.dataset.ideaUrl, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify(body),
    }).catch((error) => {
      console.error("error", error);
    });
  }

  delete(ev) {
    ev.preventDefault();

    // check to make sure its not the last
    const count = document.querySelectorAll(".tile_container")?.length;
    if (count == 1) return;

    if (!this.hasTileTarget) {
      this.destroy(ev);
    } else {
      const as = new ActionSheet({
        text: "This action will remove all items within the group.",
      });

      as.yesNoMenu(() => {
        fadeOut(this.element);
        this.destroy(ev);
      });
    }
  }

  destroy(ev) {
    fetch(ev.params.url, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.handleActions();
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  handleActions() {
    if (document.querySelectorAll(".tile_container")?.length == 1) {
      document.querySelectorAll(".destroy-container-trigger").forEach((btn) => {
        btn.classList.add("d-none");
      });
    } else {
      document.querySelectorAll(".destroy-container-trigger").forEach((btn) => {
        btn.classList.remove("d-none");
      });
    }
  }
}
