import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="field"
export default class extends Controller {
  static targets = ["field", "btn", "btnInverse"];

  connect() {}

  focus(ev) {
    // store existing value
    this.previousValue = this.fieldTarget.value;

    this.fieldTarget.select();

    this.element.classList.add("field-active");

    this.btnTargets.forEach((el) => {
      el.classList.add("show");
      el.classList.remove("d-none");
    });

    this.btnInverseTargets.forEach((el) => {
      el.classList.add("d-none");
    });

    // document.addEventListener("keydown", this.bindEscape);
    document.onkeydown = (ev) => this.bindEscape(ev);
  }

  bindEscape(ev) {
    if (ev.key === "Escape") {
      this.blur();
      document.removeEventListener("keydown", this.bindEscape);
    }
  }

  keypress(ev) {
    if (ev.key === "Enter") {
      this.blur();
    }
  }

  submit(ev) {
    ev.currentTarget.closest("form").requestSubmit();
  }

  submitted(ev) {
    this.blur();
  }

  cancel(ev) {
    ev.preventDefault();

    this.blur();
    this.fieldTarget.value = this.previousValue ? this.previousValue : "";
  }

  blur() {
    this.fieldTarget.blur();

    this.element.classList.remove("field-active");
    this.btnTargets.forEach((el) => {
      el.classList.remove("show");
      el.classList.add("d-none");
    });

    this.btnInverseTargets.forEach((el) => {
      el.classList.remove("d-none");
    });
  }
}
