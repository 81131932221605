import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["boards", "items", "title", "input"];

  connect() {
    console.log("search#connect");

    // sidebar
    const newBoard = document.querySelector("#addNew > ul > li.new-board");
    if (newBoard) {
      newBoard.remove();
    }

    document.querySelectorAll("#sidebar li.nav-item a").forEach((el) => {
      el.classList.remove("active");
    });
  }

  action(ev) {
    console.log("action", this.inputTarget.value);

    document.body.classList.remove("info-panel-active");

    if (this.inputTarget.value == "") {
      this.boardsTarget.replaceChildren();
      this.itemsTarget.replaceChildren();
      this.titleTarget.innerText = "Search";
    } else {
      this.titleTarget.innerText = "Search Results";
    }
  }
}
