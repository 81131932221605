import { Controller } from "@hotwired/stimulus";
import ActionSheet from "../modules/action_sheet";
import { fadeOut, headers, jsonHeaders } from "../utils";

export default class extends Controller {
  static targets = [
    "form",
    "grid",
    "item",
    "filtersVendor",
    "filters",
    "sort",
    "newLabel",
    "selectedPanel",
    "numberSelected",
    "selectAll",
    "btn",
  ];

  connect() {
    console.log("items#connect");
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  resize(ev) {
    console.log("resize", ev.currentTarget.value);
    const itemsPerRow = 9 - ev.currentTarget.value;
    this.gridTarget
      .querySelectorAll(".grid")
      .forEach(
        (grid) =>
          (grid.style.gridTemplateColumns = `repeat(${itemsPerRow}, 1fr)`)
      );
  }

  sort(ev) {
    ev.preventDefault();

    this.sortTarget.value = ev.params.sort;

    this.submit();
  }

  filterVendors(ev) {
    ev.preventDefault();

    const id = ev.params.filterId;

    let filters = new Set(
      this.filtersVendorTarget.value.split(",").map((f) => {
        return f.trim() !== "" ? parseInt(f, 10) : null;
      })
    );

    // Remove invalid entries (like NaN)
    filters.forEach((f) => {
      if (f === null || isNaN(f)) {
        filters.delete(f);
      }
    });

    // checkmark
    const box = ev.currentTarget.querySelector("em");
    box.classList.remove("fa-square", "fa-square-check");

    if (filters.has(id)) {
      // remove it
      filters.delete(id);
      box.classList.add("fa-square");
    } else {
      filters.add(id);
      box.classList.add("fa-square-check");
    }

    this.filtersVendorTarget.value = Array.from(filters);
    this.submit();
  }

  filter(ev) {
    ev.preventDefault();

    const val = ev.params.filter.toLowerCase();

    let filters = new Set(this.filtersTarget.value.split(","));
    filters.forEach((f) => {
      if (f === "") {
        filters.delete(f);
      }
    });

    // checkmark
    const box = ev.currentTarget.querySelector("em");
    box.classList.remove("fa-square", "fa-square-check");

    if (filters.has(val)) {
      // remove it
      filters.delete(val);
      box.classList.add("fa-square");
    } else {
      filters.add(val);
      box.classList.add("fa-square-check");
    }

    this.filtersTarget.value = Array.from(filters);
    this.submit();
  }

  searchFilters(ev) {
    console.log(ev.currentTarget.value);

    this.filterTargets.forEach((filter) => {
      if (
        filter.dataset.name
          .toLowerCase()
          .includes(ev.currentTarget.value.toLowerCase())
      ) {
        filter.style.display = "block";
      } else {
        filter.style.display = "none";
      }
    });
  }

  handleTouch(ev) {
    // check if we are in selecting mode
    if (this.element.classList.contains("select-active")) {
      ev.currentTarget.classList.toggle("selected");

      const selected = this.gridTarget.querySelectorAll(".selected");
      this.numberSelectedTarget.innerText = `${selected.length} Selected`;
      this.toggleButtons();
    } else {
      this.show(ev);
    }
  }

  toggleSelect(ev) {
    ev.preventDefault();
    console.log("toggleSelect");

    if (this.element.classList.contains("select-active")) {
      this.unselectAll(ev);
      this.element.classList.remove("select-active");
      this.selectedPanelTarget.style.display = "none";
      this.formTarget.style.display = "block";
    } else {
      this.element.classList.add("select-active");
      this.formTarget.style.display = "none";
      this.selectedPanelTarget.style.display = "flex";
      this.numberSelectedTarget.innerText = `0 Selected`;
    }

    this.toggleButtons();
  }

  show(ev) {
    ev.preventDefault();

    this.itemTargets.forEach((el) => el.classList.remove("selected"));
    ev.currentTarget.classList.add("selected");

    Turbo.visit(ev.params.url, { frame: "info_panel" });

    document.body.classList.add("info-panel-active");
  }

  toggleButtons() {
    const selected = this.gridTarget.querySelectorAll(".selected");

    if (selected.length > 0) {
      this.btnTargets.forEach((el) => el.classList.remove("d-none"));
    } else {
      this.btnTargets.forEach((el) => el.classList.add("d-none"));
    }
  }

  selectAll(ev) {
    const selected = this.gridTarget.querySelectorAll(".selected");
    if (selected.length >= this.itemTargets.length) {
      // all selected so unselect
      this.unselectAll(ev);
      this.deleteAllTarget.classList.add("d-none");
    } else {
      this.itemTargets.forEach((el) => el.classList.add("selected"));
      this.deleteAllTarget.classList.remove("d-none");
      ev.currentTarget.classList.add("fa-square-check");
      ev.currentTarget.classList.remove("fa-square");
    }

    this.toggleButtons();
  }

  unselectAll(ev) {
    this.itemTargets.forEach((el) => el.classList.remove("selected"));
    this.selectAllTarget.classList.remove("fa-square-check");
    this.selectAllTarget.classList.add("fa-square");
  }

  deleteSelected(ev) {
    const selected = this.gridTarget.querySelectorAll(".selected");

    const as = new ActionSheet({
      title: "Delete Items",
      text: `Are you sure you want to delete ${
        selected.length - 1
      } selecteditems? This action cannot be undone.`,
    });

    as.yesNoMenu(() => {
      selected.forEach((el) => {
        this.destroy(el, el.dataset.itemId);
      });
    });
  }

  destroy(el, id) {
    fetch(`/items/${id}.json`, {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then((resp) => {
        if (resp.ok) {
          fadeOut(el);
        } else {
          resp.json().then((body) => {
            const message = body.item.name
              ? `${body.item.name} - ${body.errors}`
              : body.errors;

            window.notyf.error({
              message: message,
              dismissible: true,
            });
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  addToBoard(ev) {
    ev.preventDefault();

    const selected = Array.from(this.gridTarget.querySelectorAll(".selected"));
    const ids = selected.map((item) => item.dataset.itemId);

    const url = `/items/add_to_board`;
    const body = {
      item_ids: ids,
    };

    fetch(url, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify(body),
    })
      .then((resp) => resp.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((err) => {
        window.notyf.errors(err);
      });
  }
}
