// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "./google_picker";
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import alertify from "alertifyjs";
import LocalTime from "local-time";

LocalTime.start();

window.bootstrap = bootstrap;

alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary";
alertify.defaults.theme.cancel = "btn btn-danger";
alertify.defaults.theme.input = "form-control";
import "./channels";

Element.prototype.insertChildAtIndex = function (child, position) {
  const tiles = this.querySelectorAll(
    ".tile-wrapper:not(.d-none):not(.no-drag)"
  );

  // position comes as a string
  const index = parseInt(position);

  // remember the number of tiles is one less since we removed one to reinsert
  if (tiles.length == 0 || tiles.length === index) {
    this.prepend(child);
  } else {
    this.insertBefore(child, tiles[index]);
  }
};

Turbo.StreamActions.redirect = function (ev) {
  const path = this.getAttribute("target");
  document.location.href = path;
};

Turbo.StreamActions.updateBackgroundUrl = function (ev) {
  const id = this.getAttribute("target");
  const backgroundUrl = this.getAttribute("backgroundUrl");
  document.getElementById(id).style.backgroundImage = `url(${backgroundUrl})`;
  document.getElementById(id).querySelector(".item-icon").remove();
};

Turbo.StreamActions.prependComments = function (ev) {
  const targetId = this.getAttribute("target");
  const target = document.getElementById(targetId);
  const content = this.querySelector("template").content;
  console.log("content", content, targetId);

  target.prepend(content);
  target.parentNode.scrollTop = 42;
};

Turbo.StreamActions.appendComment = function (ev) {
  const targetId = this.getAttribute("target");
  const target = document.getElementById(targetId);
  const content = this.querySelector("template").content;
  const commentId = this.getAttribute("commentId");
  console.log("content", content, targetId);

  // make sure it doesnt already exist
  const comment = document.getElementById(`comment_${commentId}`);
  console.log("commentId", commentId, comment);

  if (!comment) {
    // remove timestamp if present
    const timestamp = document.getElementById(`comment_timestamp_${commentId}`);
    timestamp?.remove();

    target?.append(content);
  }
  document.querySelector("#comments .comment:last-child")?.scrollIntoView();
};

Turbo.StreamActions.updateGrid = function (ev) {
  const session = Turbo.session;
  const requestId = this.getAttribute("requestId");
  const ideaId = this.getAttribute("ideaId");
  const position = this.getAttribute("position");
  const targetContainer = this.getAttribute("target");
  const content = this.querySelector("template").content;

  const isRecentRequest = requestId && session.recentRequests.has(requestId);

  if (!isRecentRequest && !session.navigator.currentVisit) {
    updateGrid(ideaId, position, content, targetContainer);
  } else {
    console.log("SKIP updating grid");
  }
};

function updateGrid(ideaId, position, idea, targetContainer) {
  // gets it in any container, so will delete if moved to new container
  const tile = document.getElementById(`idea_${ideaId}`);

  if (tile) {
    // remove since we reinsert at the correct position
    tile.remove();
  }

  // insert.  uses new container id to ensure inserted in right place
  const tiles = document.getElementById(targetContainer);
  tiles?.insertChildAtIndex(idea, position);

  // deal with add block
  const addBlock = tiles?.querySelector(".add-block");
  addBlock?.classList.add("not-empty");
  addBlock?.classList.remove("empty");
}

document.addEventListener("turbo:before-fetch-request", function (event) {
  window.csrfToken = document.querySelector("[name=csrf-token]")?.content;
  event.detail.fetchOptions.headers["X-CSRF-Token"] = csrfToken;
});

document.addEventListener("turbo:frame-load", function (event) {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');
  if (!csrfMetaTag) {
    const newCsrfMetaTag = document.createElement("meta");
    newCsrfMetaTag.name = "csrf-token";
    newCsrfMetaTag.content = window.csrfToken;
    document.head.appendChild(newCsrfMetaTag);
  }
});
