import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments-panel"
export default class extends Controller {
  static targets = ["field"];

  connect() {
    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.add("comments");

    document.body.classList.add("info-panel-active");
    document.body
      .querySelector("#canvas .overlay")
      ?.addEventListener("click", this.close);

    const lastComment = document.querySelector(
      ".comments-panel .comment:last-child"
    );
    lastComment?.scrollIntoView();

    this.fieldTarget.focus();
  }

  submit(ev) {
    window.setTimeout(() => {
      this.fieldTarget.value = "";
      this.fieldTarget.focus();
    }, 500);
  }

  close(ev) {
    document.body.classList.remove("info-panel-active");
    document.removeEventListener("keydown", this.bindEscape);

    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.remove("comments");
  }
}
