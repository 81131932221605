import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="field-new"
export default class extends Controller {
  connect() {
    console.log("field-new#connect");
  }

  submitted(ev) {
    console.log(
      "field-new@submitted",
      document.getElementById("new_item_fields")
    );

    document.getElementById("new_item_fields").reload();
  }
}
