import { Notyf } from "notyf";
import autoComplete from "@tarekraafat/autocomplete.js";

export function production() {
  return (
    document.head.querySelector("meta[name=rails_env]").content == "production"
  );
}

export function fadeOut(el) {
  var opacity = 1; // Initial opacity
  var interval = setInterval(function () {
    if (opacity > 0) {
      opacity -= 0.1;
      el.style.opacity = opacity;
    } else {
      clearInterval(interval); // Stop the interval when opacity reaches 0
      el.style.display = "none"; // Hide the element
    }
  }, 50);
}

export function fadeIn(el) {
  var opacity = 0; // Initial opacity
  var interval = setInterval(function () {
    if (opacity < 1) {
      opacity += 0.1;
      el.style.opacity = opacity;
    } else {
      clearInterval(interval); // Stop the interval when opacity reaches 0
      // el.style.display = 'none'; // Hide the element
    }
  }, 50);
}

export function csrfToken() {
  return document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute("content");
  // return document.querySelector("[name='csrf-token']")?.content;
}

export function headers() {
  return {
    "X-CSRF-Token": csrfToken(),
    "Content-Type": "application/json",
    Accept: "text/vnd.turbo-stream.html",
  };
}

export function turboHeaders() {
  return {
    "X-CSRF-Token": csrfToken(),
    Accept: "text/vnd.turbo-stream.html",
  };
}

export function jsonHeaders() {
  return {
    "X-CSRF-Token": csrfToken(),
    "Content-Type": "application/json",
  };
}

export function formHeaders() {
  return {
    "X-CSRF-Token": csrfToken(),
  };
}

export function errorHandler(notyf, msg) {
  notyf.dismissAll();
  notyf.error({
    message: `Error adding project - ${e}`,
    dismissible: true,
  });
}

export function addNewProject(val, url, cb) {
  const notyf = new Notyf({
    duration: 4000,
    position: { x: "right", y: "top" },
  });

  notyf.success({
    message: "Adding Project...",
    dismissible: true,
  });

  const body = {
    project: {
      name: val,
    },
  };

  fetch(url, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(body),
  })
    .then((resp) => {
      if (resp.ok) {
        resp
          .json()
          .then((json) => {
            cb(json);
          })
          .catch((e) => {
            errorHandler(notyf, e);
          });
      } else {
        resp.json().then((json) => {
          errorHandler(notyf, json.errors);
        });
      }
    })
    .catch((error) => {
      errorHandler(notyf, error);
    });
}

/**
 * Display a project selector
 *
 * @param {string} url - project URL for retrieving and adding projects
 * @param {object} args - object with needed arguments
 * @param {object} args.projectIds - array of project ids to exclude from selecting
 * @param {object} args.field - element of input field
 * @param {object} args.onAdd - callback to run after record added
 * @param {object} args.onInit - callback to run after select options initialized
 * @param {object} [args.onFocus] - callback to run after select options focused
 * @param {object} args.onSelected - callback to run after select options selected
 * @returns {object} The autocomplete object created
 */

export async function projectSelector(url, args) {
  const resp = await fetch(url);
  const options = await resp.json();

  options.forEach((project) => {
    if (args.projectIds?.includes(project.id)) {
      project.exists = true;
    } else {
      project.exists = false;
    }
  });

  const config = {
    selector: () => {
      return args.field;
    },
    placeHolder: "Select or enter new project name...",
    data: {
      src: options,
      keys: ["name"],
    },
    threshold: 0,
    resultItem: {
      element: (item, data) => {
        // Modify Results Item Style
        item.style = "display: flex; justify-content: space-between;";
        data.value.exists && item.classList.add("disabled");
        // Modify Results Item Content
        item.innerHTML = `
          <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; ">
            ${data.match}
          </span>
          <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
            ${data.value.exists ? "Exists" : ""}
          </span>`;
      },
      highlight: true,
    },
    resultsList: {
      maxResults: undefined,
      element: (list, data) => {
        if (!data.results.length && data.query.length >= 3) {
          const message = document.createElement("div");
          message.classList.add(
            "autoComplete_result_0",
            "no_result",
            "p-4",
            "cursor-pointer"
          );
          message.innerHTML = `<span>Add project <strong>"${data.query}"...</strong></span>`;
          list.prepend(message);

          message.addEventListener("click", () => {
            autoCompleteJS.close();
            addNewProject(data.query, url, args.onAdd);
          });
        }
      },
      noResults: true,
    },
    events: {
      input: {
        init: args.onInit,
        focus: (event) => {
          autoCompleteJS.start();
          if (args.onFocus) {
            args.onFocus(event);
          }
        },
        selection: (event) => {
          if (!event.detail.selection.value.exists) {
            args.onSelected(event);
          } else {
            autoCompleteJS.start();
          }
        },
      },
    },
  };

  const autoCompleteJS = new autoComplete(config);

  return autoCompleteJS;
}

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function hideModal() {
  const modal = bootstrap.Modal.getInstance("#bsTurboModal");
  modal.hide();
}

export function showPrompt(title, prompt, cb) {
  const modalElement = document.createElement("div");
  modalElement.id = "promptModal";
  modalElement.classList.add("modal", "fade");
  modalElement.tabIndex = -1;
  modalElement.setAttribute("role", "dialog");
  modalElement.innerHTML = `
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form id="customPromptForm">
          <div class="modal-header">
            <h5 class="modal-title">${title}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>${prompt}</p>
            <input type="text" id="modalPromptInput" class="form-control" autofocus="autofocus" required="true">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" id="modalPromptOkButton">OK</button>
          </div>
        </form>
      </div>
    </div>
  `;

  document.body.appendChild(modalElement);
  const modal = new bootstrap.Modal(modalElement);

  document
    .getElementById("customPromptForm")
    .addEventListener("submit", (ev) => {
      ev.preventDefault();

      const userInput = document.getElementById("modalPromptInput").value;
      modal.hide();

      cb.call(this, userInput);
    });

  modalElement.addEventListener("shown.bs.modal", () => {
    document.getElementById("modalPromptInput").focus();
  });

  modalElement.addEventListener("hidden.bs.modal", () => {
    document.body.removeChild(modalElement);
  });

  modal.show();
}
