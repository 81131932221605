import { Controller } from "@hotwired/stimulus";
import Choices from "../modules/choices";
import { jsonHeaders } from "../utils";

// Connects to data-controller="members"
export default class extends Controller {
  static targets = [
    "input",
    "boardWarning",
    "msg",
    "msgText",
    "chars",
    "btn",
    "choice",
    "permissionField",
    "permission",
    "permissionLabel",
  ];
  static values = { url: String };

  connect() {
    this.init();
  }

  init() {
    const emailInput = new Choices(this.inputTarget, {
      allowHTML: false,
      addChoices: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: false,
      classNames: {
        itemSelectable:
          ("bg-secondary", "border-none", "choices__item--selectable"),
        button: ("border-0", "choices__button"),
        noChoices: ["d-none"],
        noResults: ["d-none"],
      },
      placeholderValue: "Select teammate or enter email",
      uniqueItemText: "Only unique emails allowed",
      customAddItemText: "Please enter emails in name@domain.com format...",
      addItemFilter: (value) => {
        if (!value) {
          return false;
        }

        // first check if its a existing choice
        const found = this.choiceTargets.find((el) => el.value === value);
        if (found) return true;

        const regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const expression = new RegExp(regex.source, "i");
        return expression.test(value);
      },
    });

    this.inputTarget.addEventListener(
      "addItem",
      (event) => {
        // do something creative here...
        console.log("addItem");
        console.log(event);

        // check to see if they are already on boards
        fetch(this.urlValue, {
          headers: jsonHeaders(),
        })
          .then((resp) => resp.json())
          .then((json) => {
            const found =
              json.emails.includes(event.detail.value.toLowerCase()) ||
              json.account_ids.includes(parseInt(event.detail.value));
            if (found) {
              this.boardWarningTarget.classList.remove("d-none");
              this.boardWarningTarget.innerHTML = `<strong>${event.detail.label}</strong> has boards shared with them. If you share the project, these permissions will apply to all boards and items shared with them.`;
            } else {
              this.boardWarningTarget.classList.add("d-none");
            }
          });

        this.msgTarget.classList.remove("d-none");
        this.btnTarget.classList.remove("d-none");

        this.inputTarget
          .closest(".choices")
          .querySelector("input[type=search]").placeholder = "";
      },
      false
    );
  }

  permission(ev) {
    ev.preventDefault();
    const permission = ev.params.permission;
    this.permissionFieldTarget.value = permission;

    switch (permission) {
      case "view":
        this.permissionLabelTarget.innerText = "Viewer";
        this.btnTarget.value = "Next";
        break;
      case "comment":
        this.permissionLabelTarget.innerText = "Commenter";
        break;
      case "edit":
        this.permissionLabelTarget.innerText = "Editor";
        this.btnTarget.value = "Save";
        break;

      default:
        break;
    }
  }

  charCount(ev) {
    let charsLeft = 255 - ev.currentTarget.value.length;

    if (charsLeft <= 0) {
      ev.currentTarget.value = ev.currentTarget.value.substr(0, 255);
      charsLeft = 0;
    }

    this.charsTarget.innerText = `${charsLeft} chars left`;
  }
}
