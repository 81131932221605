import { Controller } from "@hotwired/stimulus";
import { projectSelector, jsonHeaders } from "../utils";

// Connects to data-controller="board-move"
export default class extends Controller {
  static targets = ["projectField", "btn", "name", "placeholder"];
  static values = { url: String, projectsUrl: String, projectId: Array };

  connect() {
    console.log("board-move#connect", this.projectFieldTarget, this.btnTarget);
    this.init();
  }

  async init() {
    this.autoComplete = await projectSelector(this.projectsUrlValue, {
      field: this.projectFieldTarget,
      projectIds: this.projectIdValue,
      onAdd: (json) => {
        this.projectFieldTarget.dataset.id = json.token;
        this.btnTarget.disabled = false;
      },
      onInit: (event) => {
        this.placeholderTarget.classList.add("d-none");
        this.projectFieldTarget.classList.remove("d-none");
      },
      onSelected: (event) => {
        const selection = event.detail.selection.value;
        this.projectFieldTarget.value = selection.name;
        this.projectFieldTarget.dataset.id = selection.token;
        this.btnTarget.disabled = false;
      },
    });
  }

  move(ev) {
    ev.preventDefault();

    if (this.projectFieldTarget.dataset.id) {
      this.btnTarget.disabled = true;
      this.btnTarget.innerHTML =
        '<i class="fa-duotone fa-solid fa-spinner"></i>';

      window.notyf.success({
        message: "Moving...",
        dismissible: true,
      });

      const body = {
        project_id: this.projectFieldTarget.dataset.id,
      };

      fetch(this.urlValue, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify(body),
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((json) => {
              window.notyf.dismissAll();
              window.notyf.success(
                `Movied to ${this.projectFieldTarget.value}`
              );
              location.href = json.url;
            });
          } else {
            resp.json().then((json) => {
              window.notyf.dismissAll();
              window.notyf.error({
                message: `Error moving item - ${json.errors}`,
                dismissible: true,
              });
            });
          }
        })
        .catch((error) => {
          index++;
          this.btnTarget.disabled = false;
          this.btnTarget.innerHTML = "Move";

          window.notyf.dismissAll();
          window.notyf.error({
            message: `Error moving item - ${error}`,
            dismissible: true,
          });
        });
    } else {
      console.log("error - no project selected");
    }
  }
}
